.container {
    padding: 5px;
    flex: 1 0 30%;
}

@media screen and (max-width: 800px) {
    .container {
        flex: 1 0 100%;
    }
}

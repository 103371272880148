.container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    background-color: rgb(233, 233, 233);

    h1 {
        margin: 0.5rem 0px 1rem 0px;
    }
}

.buttonClose {
    position: absolute;
    top: 10;
    right: 10;
}

.produtos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pedidoInfo {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
}

.observacaoItem {
    width: 100%;
    font-size: 1.5rem;
    background-color: white;
    padding: 10px;
    margin-top: 10px; 
    border-radius: 5px;
}

.inputObservacao {
    text-align: center;
    width: 100%;
    height: auto;

    @media (max-width: 768px) {
        height: auto;
    }
}

.buttonPedir {
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: rgb(34, 34, 34);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
}
  .layout-container {
    display: flex;
    flex-direction: column;
  }

  main {
    padding: 0px;
    display: grid;
    grid-row-gap: 20px;
    background-color: #f1ecec;
  }
  
  footer {
    background-color: #333;
    color: #f1ecec;
    padding: 20px;
  }

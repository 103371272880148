* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
}

.container {
    display: flex;
    flex-direction: column;
    margin: 0 auto; 
    max-width: 1200px; 
    padding: 20px;
    background-color: #f1ecec;
}

.products {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px;
}

.product-card {
    width:  20px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    overflow: hidden; 
}


.product-description {
    font-size: 1rem;
    margin-bottom: 10px; 
}

.product-price {
    font-weight: 600;
    font-size: 1rem;
    color: #007bff;
}

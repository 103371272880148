.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 90vw;
    height: auto;
    max-height: 90vh;
    background-color: white;
    border-radius: 10px;
    overflow-y: auto;
    padding: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
        width: 80vw;
        max-height: 80vh;
    }

    @media (min-width: 1024px) {
        width: 70vw;
        max-height: 80vh;
    }
}

.leftColumn {
    width: 100%;
    padding: 20px;
    text-align: center;

    @media (min-width: 768px) {
        width: 40%;
        text-align: left;
    }
}

.rightColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 20px;

    @media (min-width: 768px) {
        width: 60%;
    }
}

.buttonClose {
    position: absolute;
    top: 10px;
    right: 10px;
}

.imgDetalhe {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    max-height: 300px;

    @media (min-width: 768px) {
        max-height: none;
    }
}

.nomeStyle {
    font-weight: 500;
    font-size: 1.8rem;
    margin: 20px 0;

    @media (min-width: 768px) {
        font-size: 2rem;
    }
}

.descricaoStyle {
    font-weight: 300;
    font-size: 1rem;
    margin: 10px 0;

    @media (min-width: 768px) {
        font-size: 1.2rem;
    }
}

.valorStyle {
    font-weight: 600;
    font-size: 1rem;
    margin: 10px 0;
}

.observacaoItem {
    background-color: white;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
}

.inputObservacao {
    width: 100%;
    height: auto;
}

.quantidadeContainerStyle {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.quantidadeContainerStyle h2 {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
}

.buttonPedir {
    width: 100%;
    padding: 10px;
    background-color: #222;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
}

.editButton {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}
  
  .addButton {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    margin-left: 82%;
    margin-bottom: 10px;
  }
  .editButton:hover {
    background-color: #0056b3;
  }
  
.editProductArea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-top: 10px;
  }

  .inputField {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .descriptionField {
    height: 100px;
  }
  
  .editButton {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }

  .editButton:hover {
    background-color: #0056b3;
  }

.itemsStyle {
    margin: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    gap: 10px;
}

.itemsStyle > div {
    width: 30%;
    margin: 0px;
    padding: 0px;
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}
  
/* A cor warning é #fd7e14 */
.l-form-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 85vh;
}
  
.l-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.form-control{
    min-width: 20em !important;
}

.ganajo-title{
    font-size: 3em;
}

.l-s-buttonEntrar{
    padding-left: 5em !important;
    padding-right: 5em !important;
}

.l-s-buttonEntrar:hover{
    color: #FFF !important;
}

/* Efeitos */
.error-message {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }
  
.error-message.show {
    opacity: 1;
}

.error-message.leave {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}
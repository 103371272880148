.identificacaoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
}

.containerEtapas {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}

.textField {
    width: 15rem;
}

.buttonPedir {
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: rgb(34, 34, 34);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
}
* {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.container {
    padding: 15 20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    background-color: #F4F4F4;
    border-radius: 15px;
    box-shadow: 1px 2px 3px gray;
}

p {
    line-height: 20px;
}

.orderInfo {
    gap: 3rem;
    display: flex;
    flex-direction: row;
    align-items: start;
    font-size: 1rem;
}

.formaPagamento {
    font-weight: bolder;
    font-size: 1rem;
    color: var(--color-primary);
}

.orangeStyle {
    font-weight: bolder;
    font-size: 1rem;
    color: white;
    background-color: orange;
    padding: 5px;
    border-radius: 5px;
}

.greenStyle {
    font-weight: bolder;
    font-size: 1rem;
    color: white;
    background-color: #f4f4f4;
    padding: 5px;
    border-radius: 5px;
}

.redStyle {
    font-weight: bolder;
    font-size: 1rem;
    color: white;
    background-color: red;
    padding: 5px;
    border-radius: 5px;
}

.primaryColorStyle {
    font-size: 1.4em;
}

.greenItemStyle {
    font-weight: bolder;
    font-size: 1rem;
    background-color: #f4f4f4;
    padding: 8px;
    border-radius: 3px;
    margin: 6px 0px
}

.centerGridOnSmallScreen {
    @media (max-width: 600px) {
      justify-content: center;
      align-items: center;
    }
  }

/* Card */
.title{
    color: #ed6c02
}

.layout{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1024px) {

    .image {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        overflow: hidden;
    }

    .container {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        background-color: var(--color-secondary);
        border-radius: 10px;
    }

    .orderInfo {
        gap: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
    }

    h1 {
        font-size: 3rem;
        text-align: center;
    }

    h2,
    h3 {
        text-align: center;
    }

    .orangeStyle {
        font-weight: bolder;
        font-size: 1.5rem;
        color: white;
        background-color: orange;
        padding: 5px;
        border-radius: 5px;
    }

    .greenStyle {
        font-weight: bolder;
        font-size: 1.5rem;
        color: white;
        background-color: #f4f4f4;
        padding: 5px;
        border-radius: 5px;
    }

    .redStyle {
        font-weight: bolder;
        font-size: 1.5rem;
        color: white;
        background-color: red;
        padding: 5px;
        border-radius: 5px;
    }

    .primaryColorStyle {
        font-weight: bolder;
        font-size: 1.5rem;
        color: black;
        padding: 5px;
        border-radius: 5px;
    }

    .formaPagamento {
        font-weight: bolder;
        font-size: 1.5rem;
        color: var(--color-primary);
    }
}
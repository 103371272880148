.container {
    text-align: center;
    width: 100%;

    h1 {
        margin: 20px 0px;
    }
}

@media screen and (max-width: 800px){
    .statistics {
        flex-direction: column;
    }
}

.inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px; /* Espaçamento entre os inputs */
}

.inputContainer > * {
    flex: 1; /* Opcional: faz com que todos os inputs tenham o mesmo tamanho */
}

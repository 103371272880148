.edit-form-container {
    max-width: 500px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color:#d7dadef0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
  }
    
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 93px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .form-group button:hover {
    background-color: #0056b3;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza o botão Cancelar horizontalmente */
  }
  
  .button-group {
    display: flex;
    justify-content: space-between; /* Espaça os botões Salvar e Excluir */
    width: 100%; /* Garante que o grupo ocupe a largura total do contêiner */
    margin-bottom: 1rem; /* Espaço entre o grupo de botões e o botão Cancelar */
  }
  
  .center-button {
    align-self: center; /* Centraliza o botão Cancelar horizontalmente */
  }